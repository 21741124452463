/*MY OWN CSS*/
@import "breakpoint.scss";
@import "variables.scss";
@import "mixins.scss";
@import "magic_card.scss";
@import "floating_button.scss";
@import "faucet.scss";
@import "fixture.scss";
@import "player.scss";
@import "field.scss";
@import "player_selector.scss";
@import "button.scss";
@import "point.scss";
@import "player_of_the_week.scss";
@import "leaderboard.scss";
@import "blindbox.scss";

.sm-hidden{
  transition: all 0.3s ease-in-out;
  @include maxbreakpoint(sm) {
    display: none;
  }
}

.form-select {
  background: $header-background-color;
  color: $menu-color;
}

.menu-color{
  color: $menu-color;
}

.color-purple{
  color: var(--purple-color);
}

.mr-1 {
  margin-right: 10px;
}

.menu {
  &-title {
    color: $menu-color;
    font-size: 16px;
    font-weight: 700;
  }
}

.top-seller-content {
  text-overflow: ellipsis;
  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/*Standing*/
.standing-table {
  th {
    min-width: 85px;
  }
  .standing-team-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.team-heading-logo {
  animation: floating 3s ease-in-out infinite;
  width: 200px;
  height: auto;
}

@keyframes floating {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.sticky-col {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: inherit !important;
}

.fix-header {
  z-index: 2 !important;
}

.color-white{
  color: white !important;
}

.color-menu {
  color: $menu-color !important;
}

.background-primary {
  background-color: $primary-color !important;
}

.sidebar-logo-img {
  width: 150px !important;
}

.footer-logo-img {
  width: 100px !important;
}

.fixture-page {
  .thumb {
    width: 35px;
    height: auto;
  }
}

.nice-select {
  min-height: 35px;
  background: inherit;
  color: $menu-color;
  border-radius: 5px;
  padding: 10px;
  background: var(--section-background-color);
  margin-top: 10px;
  margin-bottom: 20px;
}

.pagination-area {
  display: flex;
  color: white;
  justify-content: space-between;
}

.page-selected {
  background: #1696e7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-player-wrapper {
  .player-statistic {
    background: linear-gradient(
      79deg,
      rgba(99, 69, 237, 1) 0%,
      rgba(224, 57, 253, 1) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px 0px;
    position: relative;
    padding-top: 10px;
    margin: 0px 0px 1rem;
    border-radius: 6px;
    &-wrapper {
      overflow: auto;
    }
    &-box {
      flex: 0 0 10%;
      min-height: 50px;
      padding: 0px 0.5rem;
      list-style-type: none;
      text-align: center;
    }
  }
}

.mw-300 {
  min-width: 300px !important;
}

.mw-200 {
  min-width: 200px !important;
}

.player-filter-box {
  background: var(--header-background-color);
  padding: 0px;
  margin-top: 10px;
  border-radius: 8px;
}
.player-filter-box .title {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
  padding-bottom: 12px;
  position: relative;
}
.player-filter-box .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background: var(--nft-gradient-color);
}

.player-filter-form input,
.player-filter-form textarea {
  display: block;
  border: 1px solid #242424;
  background: var(--section-background-color);
  width: 100%;
  padding: 10px 15px;
  font-size: 15px;
  color: #c9c9c9;
  border-radius: 7px;
  font-weight: 400;
}
.player-filter-form input::placeholder,
.player-filter-form textarea::placeholder {
  font-size: 15px;
  color: #c9c9c9;
  font-weight: 400;
  opacity: 0.77;
}
.player-filter-form textarea {
  min-height: 148px;
  max-height: 148px;
}

.sticky-table-modal {
  height: 400px; /* 设置模态框的高度，根据实际情况调整 */
  overflow: auto;
}

.sticky-table-modal thead th {
  position: sticky;
  top: 0;
  background-color: #1d1933;
  z-index: 2;
}

.faq-row-wrapper {
  border-radius: 5px;
  padding: 10px;
}

.faq-title {
  h2 {
    font-size: 24px;
  }
}

.flex-between-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge.bg-manager {
  background-color: var(--dark-blue-color);
  font-size: 13px;
  animation: flash 2s infinite;
}

@keyframes flash {
  0% {
    background-color: var(--dark-blue-color);
  }
  50% {
    background-color: var(--purple-color);
  }
  100% {
    background-color: var(--dark-blue-color);
  }
}

.epl-btn
{
    display: block;
    font-family: 'Oswald', sans-serif;
    width: 100%;
    background: var(--nft-gradient-color);
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    color: white;
    margin: auto;
    box-shadow: 0 2px 0 #615D6C;
    text-decoration: none;
    border-radius: 5px;
    text-align:center;
    border: 1px solid var(--nft-gradient-color);
    animation: all 0.5s ease-in-out forwards;
}

.epl-btn:hover{
  opacity: 0.8;
}

.slick-dots li button:before {
  color: $menu-color;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: $purple-color;
}

