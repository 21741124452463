.point-transaction{
    display:flex;
    justify-content:center;
    align-items:center;
    gap: 10px;
    padding: $padding;
    flex-direction: column;
    border-radius: $border-radius;
    background: $sidebar-icon-background;
    &__wrapper{
        height: 90vh;
        overflow-y: scroll;
        width: 100%;
        gap: 10px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
  
    &__box{
        width: 100%;
        display:flex;
        justify-content:center;
        align-items:center;
        gap: 10px;
        border-radius: $border-radius;
        background: $sidebar-icon-background;
        padding: $padding;
        color:white;
        font-size: 14px;
        transition: 0.3s ease-in-out;
        &--photo{
            flex: 1;
            img{
                width: 50px;
                border-radius: 100%;
                aspect-ratio: 1;
            }
            
        }
        
        &--info{
            flex: 3;
            display:flex;
            justify-content:center;
            align-items:flex-start;
            flex-direction: column;
        }

        &:hover,
        &:focus {
           background: $nft-gradient-color;
        }
    }
}