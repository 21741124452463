$gray300: #d2d2d2;
$gray500: #6e6e6e;
$gray700: #212121;
$gray900: #0f0f0f;
$blue: #29bdde;
$lightBlue: #31a49b;
$red: #ff3d3d;
$border-radius: 10px;
$lightGreen: #3dba5c;
$green: var(--sidebar-icon-background);
$darkRed: #be2c2c;
$space-size: 8px;

.field {
  border-radius: $border-radius;
  background: $green;
  padding: $space-size;
  &__center-line {
    width: 100%;
    height: 2px;
    background: $lightGreen;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    &:after {
      width: 60px;
      height: 60px;
      border: 2px solid $lightGreen;
      content: "";
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 100%;
    }
    &:before {
      width: $space-size;
      height: $space-size;
      background: $lightGreen;
      content: "";
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 100%;
    }
  }
  
  &__container {
    border-radius: $border-radius / 2;
    border: 2px solid $lightGreen;
    position: relative;
  }
  &__half {
    position: relative;
    height: 600px;
    overflow: hidden;
    &--reverse {
      transform: rotate(180deg);
      position: absolute;
      width: 100%;
      top: 0;
    }
    &.myteam {
      height: 800px;
    }
  }
  .penalty-box {
    border: 2px solid $lightGreen;
    width: 35%;
    height: 50px;
    position: absolute;
    top: -2px;
    margin: auto;
    right: 0;
    left: 0;
    &__container {
      position: relative;
      height: 100%;
      background: $green;
      z-index: 1;
    }
    &:after {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 2px solid $lightGreen;
      position: absolute;
      bottom: -20px;
      right: 0;
      left: 0;
      margin: auto;
      content: "";
      display: block;
    }
    &__infield {
      border: 2px solid $lightGreen;
      width: 35%;
      height: 38%;
      position: absolute;
      top: -2px;
      margin: auto;
      right: 0;
      left: 0;
    }
  }
  &__corner {
    border-radius: 100%;
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    border: 2px solid $lightGreen;
    &--left {
      left: -10px;
    }
    &--right {
      right: -10px;
    }
  }

  .player__name,
  .scoreboard__name,
  .scoreboard__result-home {
    color: white;
  }

  .formation {
    height: 100%;
    
  }
}

/* Squad Player*/

.formation__line {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 25%;
  padding: 5px;
  &.myteam {
    height: 20%;
  }

  &.reverse::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(187, 222, 195);
    top: 0;
    left: 0;
    z-index: -2;
    filter: opacity(0.3);
  }
}

.squad-player {
  min-width: 20%;
  &.large{
    min-width: 25%;
  }
  height: 100%;
  z-index: 2;
  padding: 10px;
  position: relative;
  &.active:after {
    background: $gradient-btn-hover;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    filter: opacity(0.5);
  }
  &.transfer:after {
    background: yellow;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    filter: opacity(0.5);
  }

  &__capatin{
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    aspect-ratio: 1;
    background: #e039fd;
    color: $menu-color;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500; 
    &.real{
      background:rgb(203, 218, 41);
      color:#fef9ff;
    }
  }
 

  &__image{
      cursor: pointer;
    
  }

  &__transfer-button {
    position: relative;
    background: $dark-blue-color;
    aspect-ratio: 1;
    width: 20px;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    &:hover {
      filter: brightness(150%);
      transform: scale(1.1);
    }
    &::after {
      content: "^";
      position: absolute;
      bottom: 2px;
      right: 1px;
      color: lightgreen;
      width: 50%;
      height: 50%;
      font-size: 10px;
    }
    &::before {
      content: "^";
      position: absolute;
      color: #f0f801;
      width: 50%;
      height: 50%;
      font-size: 10px;
      transform: translateY(4px) rotate(180deg);
    }
  }
  &__container {
    z-index: 3;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 150px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    img {
      height: 50%;
      aspect-ratio: 1/1;
    }
    button {
      width: 100%;
      height: 20%;
      font-size: 12px;
      border-radius: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
 
      background: var(--nft-gradient-color);
      color: var(--menu-color);
    }
    &--name,
    &--team,
    &--cost {
      width: 100%;
      color: white;
      text-align: center;
      max-width: 150px;
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @include maxbreakpoint(sm) {
        font-size: 10px;
      }
      &.real{
        background:rgb(203, 218, 41);
        color:#ffffff;
      }
    }
    &--name {
      background: $dark-blue-color;
    }
    &--team {
      margin-top: -20px;
      background: $purple-color;
    }
    &--cost {
      margin-top: -20px;
      width: 100%;
      color: white;
      min-height: 25px;
    }
  }
  &__cancel-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: $purple-color;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 4;
    &::after {
      content: "X";
    }
  }

  .add-button {
    display: block;
    padding-bottom: 20px;
  }

  @include maxbreakpoint(sm) {
    .add-button {
      font-size: 8px;
      height: 25%;
      padding: 0;
    }
    &__container {
      img {
        width: 50px;
        height: 50px;
      }
    }

    &__cancel-button {
      top: 10px;
      right: 0px;
    }
  }
}

.captain-modal{
  .title{
    background: $nft-gradient-color;
    max-height: 50px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
 
}


.text-center{
  text-align: center;
}

