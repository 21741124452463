@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
--card-aspect-ratio: 2 / 3
}

.magic-card {
    background: #191c29;
    width: 100%;
    aspect-ratio: var(--card-aspect-ratio);
    padding: 3px;
    position: relative;
    border-radius: 6px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 1.5em;
    color: #000000;
    cursor: pointer;
    font-family: cursive;
    color:white;
    
  }
  
  .magic-card:hover {
    color: #58c7fa;
    transition: color 1s;
  }
  .magic-card:hover:before, .magic-card:hover:after {
    animation: none;
    opacity: 1;
  }

/*   .magic-card:hover:before{
    content: attr(data-manager);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 10;
  } */
  
  .magic-card::before {
    content: "";
    width: 104%;
    height: 102%;
    border-radius: 8px;
    background-image: linear-gradient(
      var(--rotate)
      , rgba(224, 57, 253, 1), rgba(99, 69, 237, 1) 43%, #4e00c2);
      position: absolute;
      z-index: -1;
      top: -1%;
      left: -2%;
      animation: spin 2.5s linear infinite;
      opacity: 0.5;
  }



  .magic-card.owned::before{
    background-image: linear-gradient(
      var(--rotate)
      , rgb(57, 253, 122), rgb(0, 255, 0) 43%, #fff200);
      opacity: 1;
  }
  
/*   .magic-card::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    padding-top: calc(var(--card-aspect-ratio) * 100%);
    margin: 0 auto;
    transform: scale(0.8);
    filter: blur(calc(var(--card-aspect-ratio) * 100% / 6));
    background-image: linear-gradient(var(--rotate), rgba(224, 57, 253, 1), rgba(99, 69, 237, 1) 43%, #4e00c2);
    opacity: 1;
    transition: opacity 0.5s;
    animation: spin 2.5s linear infinite;
    border-radius: 10px;
  } */
  
  @keyframes spin {
    0% {
      --rotate: 0deg;
    }
    100% {
      --rotate: 360deg;
    }
  }