.blindbox{
    &-modal{
        position:relative;
        &__title{
            position:absolute;
            top:50%;
            left: 50%;
            transform:translate(-50%,-50%);
            color:white;
            font-family: "Amatic SC", sans-serif;
            font-weight: 700;
            font-size: 1.5rem;
            min-width: 400px;
            text-align:center;
            @include maxbreakpoint(sm) {
                font-size: 1rem;
                min-width: 50vw;
            }
        }
    }
}