
.fixture-gameweek {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: var(--header-background-color);
  .gameweek-btn {
    background: var(--nft-gradient-color);
    position: relative;
    color: white;
    border-radius: 10px;
    min-width: 100px;
    &.disabled{
      filter: grayscale(0.9) !important;
    }
  }
  .gameweek-btn, h5{
    @include maxbreakpoint(sm){
        font-size: 14px;
        min-width: 80px;
     }
  }

  .gameweek {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  &.point {
    flex-direction: column;
  }
  &__scorebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $sidebar-icon-background;
      aspect-ratio: 1/1;
      height: 150px;
      border-radius: $border-radius;
      @include maxbreakpoint(sm){
        width: 100% !important;
     }

      span {
        font-size: 1.1rem;
        color: $menu-color;
        font-weight: 600;
      }
      .score-text {
        background-image: linear-gradient(
          270deg,
          rgb(0, 255, 135),
          rgb(2, 239, 255)
        );
        background-size: 200% 100%;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        font-size: 6.2rem;
        font-family: PremierSans-Bold, Arial, "Helvetica Neue", Helvetica,
          sans-seriff;
        line-height: 1;
        animation: gradientAnimation 8s ease-in-out infinite;
      }

      @keyframes gradientAnimation {
        0% {
          background-position: 200% 0;
        }
        50% {
          background-position: 0% 50%;
        }
        100% {
          background-position: -100% 0;
        }
      }
    }
  }
  h5 {
    text-align: center;
  }
}
