.default_button{
    background: $nft-gradient-color;
    border: 1px solid #ccc;
    color:white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    min-width: 200px;
    &:hover{
       filter: brightness(0.8);
    }

    &[disabled]
    {
        background: $sidebar-icon-background;
        filter: brightness(0.5);
    }

}