$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media (min-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @error "無效的斷點名稱: #{$size}. 請使用以下之一：#{map-keys($breakpoints)}.";
  }
}

@mixin maxbreakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media (max-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @error "無效的斷點名稱: #{$size}. 請使用以下之一：#{map-keys($breakpoints)}.";
  }
}

/*
// 使用 mixin
.my-class {
  // 在 sm 斷點以上時套用以下樣式
  @include breakpoint(sm) {
    font-size: 16px;
    color: red;
  }
}
*/
