@mixin hide {
  display: none !important;
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

/*文字省略*/
@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin default-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  @content;
}
