.btn-wrap
{
    position: fixed;
    bottom: 15%;
    right: 10px;
    z-index: 10;
}

.floating-btn
{
    display: block;
    font-family: 'Oswald', sans-serif;
    max-width: 200px;
    background: var(--nft-gradient-color);
    position: relative;
    z-index: 1;
    padding: 20px 40px;
    color: white;
    margin: auto;
    box-shadow: 0 2px 0 #615D6C;
    text-decoration: none;
    border-radius: 5px;
    animation: bounce 2s ease-in-out forwards;
    animation-iteration-count: infinite;
}

.shadow
{
    display: block;
    width: 20%;
    height: 0px;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    background: #6F8AB7;
    border-radius: 50%;
    animation: bounce-shadow 2s ease-in-out forwards;
    animation-iteration-count: infinite;
}

  
@keyframes bounce
{
    0%{
        transform: translateY(0px)
    }
  
  50%{
    transform: translateY(20px)
  }
   
} 
 
@keyframes bounce-shadow
{
    0%
    {
        width: 1%;
        height: 2px;
        opacity: .1;
    }
  50%
  {
    width: 90%;
    height: 6px;
    opacity: 0.3;
  }
  100%
  {
    width: 1%;
    height: 0px;
    opacity: 0;
  }
}
 