@import url("https://fonts.googleapis.com/css2?family=Railway&family=Amatic+SC:wght@400;700&display=swap");
:root {
  --primary: #a5004d;
  --white: #f3f3f3;
}

.leaderboard {
  height: 100vh;

  font-family: "Railway", sans-serif;
  background: $primary-color;
  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .container {
    width: 100%;
    max-width: 600px;
    padding: 2em 0;
    display: grid;
    grid-template-rows: 400px 1fr;
    grid-column-gap: 1em;
    overflow-x:auto;
  }

  .leaders {
    background:linear-gradient(
      79deg,
      rgba(99, 69, 237, 1) 0%,
      rgb(91, 11, 202) 100%
    );
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 2em;
    display: grid;
    grid-template-rows: 20% 1fr;
    justify-items: center;
    h2 {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      padding-bottom: 40px;
      /* font-family: "Amatic SC", sans-serif; */
      color: var(--white);
    }

    ul {
      width: 420px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-end;
      grid-gap: 10px;
      @include maxbreakpoint(sm) {
        width: 300px;
      }
    }

    .lead-gamers {
      display: flex;
      flex-direction: column;
      justify-content: end;

      &__photo {
        width: 108px;
        margin: 0 auto;
      }

      .podium {
        padding: 1em;
        text-align: center;
        background-color: var(--white);
        height: 160px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
        
        h4 {
          font-family: "Amatic SC", sans-serif;
          font-size: 1.8rem;
          color: var(--primary);
        }
        p {
          color: rgb(73, 73, 73);
          margin: 5px 0;
          font-size: 2rem;
        }
      }

      .pod-1 {
        height: 100px;
      }
      .pod-3 {
        height: 130px;
      }
    }
  }

  .board {
    background-color: var(--white);

    border-radius: 0 0px 10px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
      h2 {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 200;
        padding: 30px 0;
        font-weight: 700;
        font-family: "Amatic SC", sans-serif;
        color: black;
      }

      ul {
        position: relative;
      }
    
  }
  .gamer-item {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: minmax(40px, 1fr);
    grid-gap: 1em;
    align-items: center;
    border-bottom: 1px solid rgb(233, 233, 233);
    transition: background-color 0.2s;
    padding: 10px;
  }
  .gamer-item:hover {
    background-color: hsla(0, 0%, 74%, 0.102);
  }
  .gamer-item:last-child {
    border: none;
  }
  .gamer-item img {
    height: 100%;
    width: 100%;
  }
  .gamer-item__photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: var(--primary);
    position: relative;
  }
  .gamer-item__info h4 {
    font-family: "Amatic SC", serif;
    font-size: 1.7rem;
    color: var(--primary);
    margin-left: 20px;
    @include maxbreakpoint(sm) {
      max-width:200px;
      text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
    }
  }
  .gamer-item__points {
    text-align: right;
  }
  .gamer-item__points p {
    font-size: 1.5rem;
    color: rgb(73, 73, 73);
  }
  .gamer-item__points img {
    width: 20px;
    cursor: pointer;
  }
  .gamer-item__points img:last-child {
    transform: scaleY(-1);
  }

  .ranking {
    position: absolute;
    bottom: 0;
    right: -10px;
    z-index: 10;
    width: 20px;
    height: 20px;
    background-color: #1ca1fa;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 0.7rem;
  }

  .ranking-lead {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 34px;
    height: 34px;
    background-color: #1cfa8b;
    border-radius: 50%;
    display: grid;
    place-items: center;
  }

  .slide-move {
    transition: all 0.5s ease-out;
  }

  .active {
    animation: rise 0.4s ease-out forwards;
  }

  @keyframes rise {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    80% {
      transform: translateY(-5px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
    }
  }
}
