
.player-selector{
    @include border-radius($border-radius);
    background: $sidebar-icon-background;
    padding: $padding;
    display:flex;
    flex-direction:column;
    justify-content:center;
    gap: 20px;
    &__player{
        @include border-radius($border-radius);
        background: $sidebar-icon-background;
        padding: $padding;
        transition: all 0.3s ease-in-out;
        @include flexbox();
        color: $menu-color;
        .playerImage{
            border-radius: 50%;
            height: 50px;
            cursor: pointer;
        }
        &:hover{
            background: $gradient-btn-hover;
            filter: brightness(110%);
            
        }
        &-image{
            width: 20%;
        }
        &-info{
            width: 60%;
            padding: 10px;
            display:flex;
            flex-direction: column;
        }
        &-price{
            width: 20%;
            display:flex;
            flex-direction: column;
            cursor: pointer;
        }
    }
    .reset-button{
        background: $gradient-btn-hover;
        @include border-radius($border-radius);
        width: 100%;
        padding: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        cursor: pointer;
        color:$menu-color;
        transition: all 0.3s ease-in-out;
        &:hover{
            filter: brightness(120%);
        }
    }
}

.filtered-player-container{
    overflow-y: auto;
    max-height: 100vh;
    overflow-x: none;
}

.scoreboard{
    background: $sidebar-icon-background;
    @include border-radius($border-radius);
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    gap: 10px;
    padding: 0 $padding $padding $padding;
    color: $menu-color;
    &__title{
        font-size: 1rem;
        font-weight: 600;
        background: $gradient-btn-hover;
        width: 100%;
        text-align:center;
        border-radius: 0 0 $padding $padding;
    }
    &__detail{
        width: 100%;
        @include flexbox(){
            @include maxbreakpoint(sm) {
                flex-direction: row;
              }
        };
        &__item{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items:center;
            flex-direction: column;
            gap: 10px;
            .success, .fail{
                min-width: 150px;
                text-align:center;
                
                color: white;
            }
            
            .success{
                background: yellowgreen;
            }
            .fail{
                background: red;
            }  
            button{
                background: $purple-color;
                border: none;
                border-radius: 5px;
                padding:5px 10px;
                min-width: 150px;
                color: $menu-color;
                transition: all 0.3s ease-in-out;
                &:hover{
                    background: $dark-blue-color;
                }
            }

            &.transfer{
                @include maxbreakpoint(sm) {
                width: 30%;
                margin-inline:5px;
                font-size: 14px !important;
                button{
                    min-width:100px;
                }
                .success, .fail{
                    min-width: 100%;
                }
            }
            }

        }
    }
}

