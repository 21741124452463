.faucet{
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100vh;
  &-wrapper{
    width:100%;
    max-width: 500px;
    padding: 20px;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    img{
      width: 100px;
      height: 100px;
    }

    * {
      margin: 10px;
    }


  }

}