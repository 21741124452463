$font-family-body: "Poppins", sans-serif;
$font-family-heading: "Poppins", sans-serif;
$primary-color: #1d1933;
$secondary-color: #131129;
$body-text-color: #c9c9c9;
$heading-text-color: #fff;
$paragraph-text-color: #c9c9c9;
$nft-gradient-color: linear-gradient(
  79deg,
  rgba(99, 69, 237, 1) 0%,
  rgba(224, 57, 253, 1) 100%
);
$gradient-btn-hover: linear-gradient(
  90deg,
  rgba(224, 57, 253, 1) 0%,
  rgba(99, 69, 237, 1) 100%
);
$facebook: #3b5998;
$twitter: #00acee;
$linkedin: #1976d2;
$pinterest: #3b5998;
$youtube: #c4302b;
$skype: #00aff0;
$section-background-color: #070b24;
$header-background-color: #1c1832;
$sidebar-icon-background: linear-gradient(
  rgb(41, 38, 73) 0%,
  rgba(19, 17, 41, 0) 100%
);
$icon-color: #59606b;
$icon-border-color: #2a2547;
$header-border-color: #222121;
$menu-color: #d4cdcd;
$header-form-border-color: #322c50;
$purple-color: #e039fd;
$dark-blue-color: #6345ed;

$border-radius: 5px;
$padding: 10px;